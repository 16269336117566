import React, {useState, useEffect, useContext, useRef} from 'react';
import './ViewArticle-alt.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext2';
import Header from '../components/Header';
import { sizeLimit } from '../context/StaticValues';

const ViewArticle = () => {

    const {jID, aID} = useParams();
    const { communicate } = useContext(AuthContext)

    const [title, setTitle] = useState('');
    const [journaltitle, setJournalTitle] = useState('');
    const [articleURL, setArticleURL] = useState('');
    const [queries, setQueries] = useState([]);
    const [inAddMode, setInAddMode] = useState(false);
    const [inEditMode, setInEditMode] = useState(false)
    const [textValue, setTextValue] = useState('');
    const [isSendDisabled, setIsSendDisabled] = useState(true);
    const [isAddDisabled, setIsAddDisabled] = useState(true);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isAnswered, setIsAnswered] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [attachmentURL, setAttachmentURL] = useState(null)
    const navigate = useNavigate()
    const [originalInfo, setOriginalInfo] = useState({})
    const [info, setInfo] = useState({})
    const [file, setFile] = useState(undefined);
    const [sizeWarning, setSizeWarning] = useState(false);
    const [formatWarning, setFormatWarning] = useState(false);
    const [secondTime, setSecondTime] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    let [logoURL, setLogoURL] = useState(null)
    const undeletables = 2
    // const [refresh, setRefresh] = useState(false)

    const refreshForSend = () => {
        setQueries([])
        setInAddMode(false)
        setInEditMode(false)
        setTextValue('')
        setIsAddDisabled(true)
        setIsSaveDisabled(true)
        setInfo({})
        setOriginalInfo({})
        setFile(undefined)
        fileInput.current.value = ''
        setSizeWarning(false)
        setFormatWarning(false)
        setIsSendDisabled(true)
        setIsOpen(false)
        setIsAnswered(false)
        setIsWaiting(false)
        setAttachmentURL(null)
        setSecondTime(false)
        communicate('/api/get-queries', handleGetQueries, 'POST', {'aID': aID})
    }

    const refreshForEdit = () => {
        const resetURL = (file !== undefined)
        setTitle('')
        setInAddMode(false)
        setInEditMode(false)
        setTextValue('')
        setIsAddDisabled(true)
        setIsSaveDisabled(true)
        setInfo({})
        setOriginalInfo({})
        setFile(undefined)
        fileInput.current.value = ''
        setSizeWarning(false)
        setFormatWarning(false)
        if (resetURL) {
            communicate('/api/get-article-url', handleArticleURL, 'POST', {'unique_id': aID})
        }
        communicate('/api/get-article-info', handleGetArticleInfo, 'POST', {'aID': aID})
    }

    useEffect(() => {
        // getArticleURL();
        communicate('/api/get-article-url', handleArticleURL, 'POST', {'unique_id': aID})
        communicate('/api/get-journal-title', journalTitleHandler, 'POST', {'unique_id':`${jID}`})
        communicate('/api/get-queries', handleGetQueries, 'POST', {'aID': aID})
        // communicate('/api/get-article-info', handleGetArticleInfo, 'POST', {'aID': aID})
    }, []);

    useEffect(() => {
        if (isOpen) {
            communicate('/api/get-article-info', handleGetArticleInfo, 'POST', {'aID': aID})
        }
    }, [isOpen])

    const handleGetArticleInfo = (result) => {
        if (result?.done) {
            setInfo(result.data)
            setOriginalInfo(result.data)
            if ((info.title !== undefined) && (title !== info.title)) {
                setTitle(info.title)
            }
        }
        else if (result?.error !== undefined){
            console.log(result.error)
        }
    }

    const journalTitleHandler = (result) => {
        if (result?.done) {
            setJournalTitle(result.title)
            setLogoURL(result.logo)
            return
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
        navigate('/')
    }

    useEffect(() => {
        document.title = title + ' | Science Proof';
    }, [title]);

    useEffect(() => {
        if (queries.length > 0) {
            setIsSendDisabled(false);
        }
        else {
            setIsSendDisabled(true);
        }
    }, [queries]);

    useEffect(() => {
        if (textValue.length > 0) {
            setIsAddDisabled(false);
        }
        else {
            setIsAddDisabled(true);
        }
    }, [textValue]);

    const handleArticleURL = (result) => {
        if (result?.done) {
            setArticleURL(result.pdf_url);
            setTitle(result.title);
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
    }

    const handleGetQueries = (result) => {
        if (result?.done) {
            setQueries(result.queries);
            setIsOpen(result.status === 'ie')
            setIsAnswered(result.status === 'ac')
            setIsWaiting(result.status === 'wr')
            if (result['file'] !== undefined) {
                setAttachmentURL(result['file'])
            }
        }
        else if (result?.error !== undefined){
            console.log(result.error)
        }
    }

    // const getArticleURL = async () => {
    //     communicate('/api/get-article-url', handleArticleURL, 'POST', {'unique_id': aID})
    // }

    const addQueryHandler = (result) => {
        if (result?.done) {
            setQueries([...queries, {'id': result['qID'], 'q':textValue, 'a':null}]);
            setInAddMode(false);
            setTextValue('');
        }
        else if (result?.error !== undefined){
            console.log(result.error)
        }
        setIsLoading(false)
    }

    const addQuery = () => {
        setIsLoading(true)
        communicate('/api/add-query', addQueryHandler, 'POST', {'aID': aID, 'query': textValue})
    }

    const deleteQueryHandler = (result) => {
        if (result?.done) {
            setQueries(queries.filter((query) => (query.id !== result.qID)));
        }
        else if (result?.error !== undefined){
            console.log(result.error)
        }
        setIsLoading(false)
    }

    const deleteQuery = (index) => {
        if (!isLoading) {
            setIsLoading(true)
            communicate('/api/delete-query', deleteQueryHandler, 'POST', {'qID': index})
        }
    }

    const cancelled = () => {
        setInAddMode(false);
        setTextValue('');
    }

    const sendToAuthor = () => {
        setIsLoading(true)
        communicate('/api/send-to-author', handleSend, 'POST', {'aID': aID})
    }

    const handleSend = (result) => {
        if (result?.done) {
            // navigate(0)
            refreshForSend()
        }
        else if (result?.error !== undefined){
            console.log(result.error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        document.body.style.cursor= isLoading ? 'wait' : 'default'
    }, [isLoading])

    const fileInput = useRef()

    if (typeof String.prototype.endsWith !== 'function') {
        // eslint-disable-next-line
        String.prototype.endsWith = function(suffix) {
            return this.toLowerCase().indexOf(suffix, this.length - suffix.length) !== -1;
        };
    }

    const handleFileChange = (event) => {
        const sizeIsOK = event.target.files[0].size < (sizeLimit * 1024 * 1024)
        const formatIsOK = event.target.files[0].name.endsWith('.pdf')
        if (sizeIsOK && formatIsOK) {
            setSizeWarning(false)
            setFormatWarning(false)
            setFile(event.target.files[0])
        }
        else {
            setFile(undefined)
            fileInput.current.value = ''
            setSizeWarning(!sizeIsOK)
            setFormatWarning(!formatIsOK)
        }
    };

    const checkValues = (value) => {
        return info.articleNumber !== value && info.title !== value && info.author !== value && info.email !== value && info.doi !== value
    }

    const saveArticle = () => {
        if (checkValues(undefined) && checkValues('')) {
            setIsLoading(true)
            const formData = new FormData();
            formData.append('articleNumber', info.articleNumber);
            formData.append('title', info.title);
            formData.append('author', info.author);
            formData.append('email', info.email);
            formData.append('doi', info.doi);
            if (file !== undefined) {
                formData.append('file', file);
            }
            formData.append('aID', aID);
            communicate('/api/update-article', handleAddArticle, 'POST', formData, null, false)
        }
    }

    const handleAddArticle = (result) => {
        if (result?.done) {
            // navigate(0)
            refreshForEdit()
        }
        else if (result?.error !== undefined){
            console.log(result.error)
        }
        setIsLoading(false)
    }

    const isInfoChanged = () => {
        if (file !== undefined) {
            return true
        }
        for (const [key, value] of Object.entries(info)) {
            if (originalInfo[key] !== value) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        if (checkValues(undefined) && checkValues('') && isInfoChanged()) {
            setIsSaveDisabled(false)
        }
        else{
            setIsSaveDisabled(true)
        }
    }, [info, file])

    const unselectFile = () => {
        setFile(undefined)
        fileInput.current.value = ''
        setSizeWarning(false)
        setFormatWarning(false)
    }

    return(
        <>
            <Header title={journaltitle} logo={logoURL} />
            <div className='articleview'>
                <div className='articleview-main'>
                    <object data={'https://scienceproof.xyz' + articleURL} type="application/pdf" width="100%" height="100%">
                        {/* <a href={articleURL}>{articleURL}</a> */}
                    </object>
                </div>
                <div className='articleview-side'>
                    <div className={isOpen ? 'articleview-controls' : 'hide'}>
                        <div className={'articleview-buttons' + ((inAddMode || inEditMode || secondTime) ? ' hide' : '')}>
                            <button className='articleview-button' onClick={() => setInAddMode(true)}>Add Query</button>
                            <button className={'articleview-button' + (Object.keys(info).length === 0 ? ' disabled-button' : '')} disabled={Object.keys(info).length === 0} onClick={() => setInEditMode(true)}>View/Edit Details</button>
                            <button className={'articleview-button' + (isSendDisabled ? ' disabled-button' : '')} disabled={isSendDisabled} onClick={() => setSecondTime(true)}>Send to Author</button>
                        </div>
                        <div className={secondTime ? 'articleview-secondtime' : 'hide'}>
                            Are you sure?
                            <div className='articleview-add-buttons'>
                                <button className={'articleview-button add' + (isLoading ? ' disabled-button' : '')} disabled={isLoading} onClick={() => sendToAuthor()}>Yes</button>
                                <button className={'articleview-button add' + (isLoading ? ' disabled-button' : '')} disabled={isLoading} onClick={() => setSecondTime(false)}>No</button>
                            </div>
                        </div>
                        <div className={'articleview-edit' + (inEditMode ? '' : ' hide')}>
                            <div className='articleview-edit-section'>
                                <span className='articleview-input-label'>Article No.:</span>
                                <input className='articleview-input' type='text' name='articleNumber' value={info.articleNumber || ''} onChange={(event) => (setInfo(oldValues => ({...oldValues, articleNumber: event.target.value})))} />
                            </div>
                            <div className='articleview-edit-section'>
                                <span className='articleview-input-label'>Title:</span>
                                <input className='articleview-input' type='text' name='title' value={info.title || ''}  onChange={(event) => (setInfo(oldValues => ({...oldValues, title: event.target.value})))} />
                            </div>
                            <div className='articleview-edit-section'>
                                <span className='articleview-input-label'>Author's Name:</span>
                                <input className='articleview-input' type='text' name='author' value={info.author || ''}  onChange={(event) => (setInfo(oldValues => ({...oldValues, author: event.target.value})))} />
                            </div>
                            <div className='articleview-edit-section'>
                                <span className='articleview-input-label'>Author's Email:</span>
                                <input className='articleview-input' type='text' name='email' value={info.email || ''}  onChange={(event) => (setInfo(oldValues => ({...oldValues, email: event.target.value})))} />
                            </div>
                            <div className='articleview-edit-section'>
                                <span className='articleview-input-label'>DOI:</span>
                                <input className='articleview-input' type='text' name='doi' value={info.doi || ''}  onChange={(event) => (setInfo(oldValues => ({...oldValues, doi: event.target.value})))} />
                            </div>
                            <div className='articleview-edit-section'>
                                <span className='articleview-input-label'>File:</span>
                                <input className='articleview-file-input' type='file' name='pdf' accept='application/pdf' ref={fileInput} onChange={handleFileChange}></input>
                                <div  className='articleview-warnings'>
                                    <span className={'articleview-file-warning' + (sizeWarning ? ' red' : '')}>Size limit: {sizeLimit} Megabytes</span>
                                    <span className={'articleview-file-warning red' + (formatWarning ? '' : ' hide')}>File should be PDF</span>
                                </div>
                            </div>
                            <div className='articleview-add-buttons'>
                                <button className={'articleview-button add' + (isSaveDisabled || isLoading ? ' disabled-button' : '')} disabled={isSaveDisabled || isLoading} onClick={() => saveArticle()}>Save</button>
                                <button className={'articleview-button add' + (isLoading ? ' disabled-button' : '')} disabled={isLoading} onClick={() => {setInEditMode(false); setInfo(originalInfo); unselectFile()}}>Cancel</button>
                            </div>
                        </div>
                        <div className={'articleview-add-query' + (inAddMode ? '' : ' hide')}>
                            <textarea className='articleview-textarea' value={textValue} onChange={(event) => (setTextValue(event.target.value))} />
                            <div className='articleview-add-buttons'>
                                <button className={'articleview-button add' + (isAddDisabled || isLoading ? ' disabled-button' : '')} disabled={isAddDisabled || isLoading} onClick={() => addQuery()}>Add</button>
                                <button className={'articleview-button add' + (isLoading ? ' disabled-button' : '')} disabled={isLoading} onClick={() => cancelled()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div className={(isOpen || isWaiting) ? 'hide' : 'articleview-file'}>
                        {attachmentURL !== null && <Link to={'https://scienceproof.xyz' + attachmentURL} target='_blank'>Download attached file</Link>}
                        {attachmentURL === null && 'No attached file'}
                    </div>
                    <div className='articleview-queries'>
                        {
                            queries.map((query, id) => (
                                <div key={id} className='articleview-querygroup'>
                                    <div className={'articleview-query' + ((isOpen && id >= undeletables) ? ' open-query' : ' closed-query')}>
                                        <span>{(id+1)+'.'}</span>
                                        <span className='articleview-question'>{query.q}</span>
                                        {id >= undeletables && <div className='articleview-delete-container'><span className={isOpen ? 'articleview-delete' : 'hide'} onClick={() => deleteQuery(query.id)}>×</span></div>}
                                    </div>
                                    {(!isOpen && !isWaiting) &&
                                    <div className='articleview-answer'>
                                        {(!isAnswered || query.a === null) && <span className='articleview-notanswered-title'>Not answered</span>}
                                        {(isAnswered && query.a !== null) && <span className='articleview-answered-title'>Answer:</span>}
                                        {(isAnswered && query.a !== null) && <span className='articleview-answer-text'>{query.a}</span>}
                                    </div>}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewArticle;