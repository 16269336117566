import React, {useState, useEffect, useContext} from 'react';
import './Login-alt.css'
import AuthContext from '../context/AuthContext2';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Login = () => {

    let {loginUser, loginMessage, user, loggingIn} = useContext(AuthContext);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [resetMode, setResetMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        document.title = 'Login | Science Proof';
    }, []);

    useEffect(() => {
        if (user !== null) {
            const { fromSpecificPage } = state || {};
            if (fromSpecificPage) {
                navigate('/', { replace: true });
            } else {
                navigate(-1);
            }
        }
    }, [user]);
    
    const [inputs, setInputs] = useState({});
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const resetPassword = async () => {
        if (inputs.email !== undefined && inputs.email !== '') {
            setIsLoading(true)
            try {
                let response = await fetch('https://scienceproof.xyz/api/password-reset', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({'email': inputs.email})
                })
                // let data = await response.json()
                if (response.status === 200) {
                    navigate(0)
                }
                else {
                    console.log(response.statusText)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        document.body.style.cursor= isLoading ? 'wait' : 'default'
    }, [isLoading])

    return (
        <div className='login'>
            <img src='/static/media/logo.svg' alt='logo' />
            {!resetMode && <form className='login-form' onSubmit={loginUser}>
                <div className='login-forminputs'>
                    <label><span className='login-label'>Email:</span><input className='login-text' type='text' name='email' value={inputs.email || ''} onChange={handleChange}></input></label>
                    <label><span className='login-label'>Password:</span><input className='login-text' type='password' name='password' value={inputs.password || ''} onChange={handleChange}></input></label>
                </div>
                <button type='submit' className={'login-button' + (loggingIn ? ' disabled' : '')} disabled={loggingIn}>Login</button>
            </form>}
            {resetMode && <div className='login-form'>
                <div className='login-forminputs'>
                    <label><span className='login-label'>Email:</span><input className='login-text' type='text' name='email' value={inputs.email || ''} onChange={handleChange}></input></label>
                </div>
                <button className={'login-button' + (isLoading ? ' disabled' : '')} disabled={isLoading} onClick={resetPassword}>Reset Password</button>
            </div>}
            {resetMode ? <Link onClick={() => setResetMode(false)}>Login</Link> : <Link onClick={() => setResetMode(true)}>Reset password</Link>}
            {(!resetMode && (loginMessage !== null)) && <p className='login-message'>{loginMessage}</p>}
        </div>
    )
}

export default Login