import React from 'react'
import './Checkbox-alt.css'

const Checkbox = ({boxChecked, setBoxChecked}) => {
    return (
        <div className='checkbox' onClick={setBoxChecked}>
            <div className={boxChecked ? 'checked' : 'unchecked'}></div>
        </div>
    )
}

export default Checkbox