import React, {useState, useEffect, useRef} from 'react';
import './ResponseView-alt.css';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { sizeLimit } from '../context/StaticValues';

const ResponseView = () => {

    const {token} = useParams();
    const [journaltitle, setJournalTitle] = useState('');
    const [articleURL, setArticleURL] = useState('');
    const [queries, setQueries] = useState([]);
    // const [textValue, setTextValue] = useState('');
    const [values, setValues] = useState({});
    const [file, setFile] = useState(undefined)
    const [fileName, setFileName] = useState(undefined)
    const [sizeWarning, setSizeWarning] = useState(false)
    // const [aID, setAID] = useState(undefined)
    const [isAvailable, setIsAvailable] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [isDone, setIsDone] = useState(false)
    const [publication, setPublication] = useState(undefined)
    const [secondChance, setSecondChance] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [emptiesMessage, setEmptiesMessage] = useState('')
    let [logoURL, setLogoURL] = useState(null)

    const navigate = useNavigate()

    let communicate = async (url, returnData, method='GET', body=null, contentType='application/json', stringify = true) => {
        let request = {method: method,
            headers: {
                // 'Authorization': 'ProofService ' + String(authToken.access)
            }
        }
        if (contentType !== null && method !== 'GET') {
            request['headers']['Content-Type'] = contentType
        }
        if (body !== null) {
            if (stringify) {
                request['body'] = JSON.stringify(body)
            }
            else {
                request['body'] = body
            }
        }
        try {
            let response = await fetch('https://scienceproof.xyz' + url, request)
            let data = await response.json()
            if (response.status === 200) {
                returnData(data)
            }
            else {
                returnData({'done': false})
            }
        } catch (error) {
            returnData({'done': false, 'error': error})
        }
    }

    useEffect(() => {
        communicate('/api/proof-data', handleProofData, 'POST', {'token': token})
    }, []);

    useEffect(() => {
        if (publication !== undefined) {
            document.title = publication;
        }
        else {
            document.title = 'Science Proof';
        }
    }, [publication])

    const handleProofData = (result) => {
        if (result?.done) {
            setArticleURL(result.pdf_url);
            setJournalTitle(result.journal_title)
            setPublication(result.publication_title)
            setQueries(result.queries);
            setIsAvailable(true)
            setLogoURL(result.journal_logo)
        }
        else {
            // if (result?.error) {
            //     console.log(result.error)
            // }
            setIsAvailable(false)
        }
        setIsReady(true)
    }

    // useEffect(() => {
    //     console.log(values[20]?.trim() === '')
    // }, [values])

    // const checkValues = () => {
    //     values[20]?.trim() === ''
    //     for (const [key, value] of Object.entries(info)) {
    //         if (originalInfo[key] !== value) {
    //             return true
    //         }
    //     }
    // }

    const firstSubmit = () => {
        var empties = []
        let qLength = queries.length
        for (var i=0; i < qLength; i++) {
            if (values[queries[i].id] === undefined || values[queries[i].id]?.trim() === '') {
                empties = [...empties, i + 1]
            }
        }
        const emptiesLength = empties.length
        if (emptiesLength === 1) {
            setEmptiesMessage(`Query ${empties[0]} is not answered.`)
        }
        else if (emptiesLength >= 2) {
            var loopTimes = emptiesLength - 2
            var message = 'Queries '
            for (i = 0; i < loopTimes; i++) {
                message += `${empties[i]}, `
            }
            setEmptiesMessage(message + `${empties[loopTimes]} and ${empties[loopTimes + 1]} are not answered.`)
        }
        else {
            setEmptiesMessage('')
            setSecondChance(true)
            return
        }
        setSecondChance(false)
    }

    const submit = () => {
        setIsLoading(true)
        var empties = []
        var answers = {}
        const formData = new FormData();
        formData.append('token', token)
        let qLength = queries.length
        for (var i=0; i < qLength; i++) {
            if (values[queries[i].id] === undefined || values[queries[i].id]?.trim() === '') {
                empties = [...empties, i + 1]
                answers[queries[i].id] = null
            }
            else {
                answers[queries[i].id] = values[queries[i].id]
            }
        }
        const emptiesLength = empties.length
        if (emptiesLength === 1) {
            setEmptiesMessage(`Query ${empties[0]} is not answered.`)
            setSecondChance(false)
            setIsLoading(false)
            return
        }
        else if (emptiesLength >= 2) {
            var loopTimes = emptiesLength - 2
            var message = 'Queries '
            for (i = 0; i < loopTimes; i++) {
                message += `${empties[i]}, `
            }
            setEmptiesMessage(message + `${empties[loopTimes]} and ${empties[loopTimes + 1]} are not answered.`)
            setSecondChance(false)
            setIsLoading(false)
            return
        }
        else {
            setEmptiesMessage('')
        }
        if (file !== undefined && file.size <= (sizeLimit * 1024 * 1024)) {
            formData.append('file', file)
        }
        formData.append('answers', JSON.stringify(answers))
        communicate('/api/response', handleSend, 'POST', formData, null, false)
    }

    const handleSend = (result) => {
        if (result?.done) {
            setIsDone(true)
        }
        else {
            // if (result?.error) {
            //     console.log(result.error)
            // }
            navigate(0)
        }
        setIsLoading(false)
    }

    const handleFileChange = (event) => {
        if (event.target.files[0].size < (sizeLimit * 1024 * 1024)) {
            setSizeWarning(false)
            setFile(event.target.files[0])
        }
        else {
            fileInput.current.value = ''
            setSizeWarning(true)
        }
    }

    useEffect(() => {
        if (file !== undefined) {
            setFileName(file.name)
        }
        else {
            setFileName(undefined)
        }
    }, [file])

    const fileInput = useRef()

    const handleUnselect = () => {
        fileInput.current.value = ''
        setFile(undefined)
    }

    useEffect(() => {
        document.body.style.cursor= isLoading ? 'wait' : 'default'
    }, [isLoading])

    return(
        <div className='response'>
            {isDone && <div className='not-available'>
                <h2>Thank You</h2>
                <p>Your response is successfully received</p>
            </div>}
            <div className={(isReady & !isAvailable & !isDone) ? 'not-available' : 'hidden'}>
                <h2>This page is not available</h2>
                <p>Maybe your link has expired</p>
            </div>
            <div className={(isReady & isAvailable & !isDone) ? 'responseview-container' : 'hidden'}>
                <Header title={journaltitle} logout={false} logo={logoURL} />
                <div className='responseview'>
                    <div className='responseview-main'>
                        <object data={'https://scienceproof.xyz' + articleURL} type="application/pdf" width="100%" height="100%">
                            {/* <a href={articleURL}>{articleURL}</a> */}
                        </object>
                    </div>
                    <div className='responseview-side'>
                        <div className='responseview-buttons'>
                            {!secondChance &&
                            <div>
                                <button className={'responseview-button' + (isLoading ? ' disabled' : '')} disabled={isLoading} onClick={firstSubmit}>Submit</button>
                                {(emptiesMessage !== '') &&
                                <div className='responseview-empties-message'>
                                    {emptiesMessage}
                                </div>}
                            </div>}
                            {secondChance && <div className='responseview-secondchance'>
                                Are you sure?
                                <div className='responseview-secondchance-buttons'>
                                    <button className={'responseview-button' + (isLoading ? ' disabled' : '')} disabled={isLoading} onClick={submit}>Yes</button>
                                    <button className={'responseview-button' + (isLoading ? ' disabled' : '')} disabled={isLoading} onClick={() => setSecondChance(false)}>No</button>
                                </div>
                                </div>}
                        </div>
                        <div className='responseview-file'>
                            <div>Attach a file if needed:</div>
                            <div className='responseview-fileselector'>
                                <input className={file !== undefined ? 'hide' : ''} type='file' name='attachment' ref={fileInput} onChange={handleFileChange}></input>
                                <div className={(file !== undefined ? '' : 'hide') + (sizeWarning && ' red')}>{fileName}</div>
                                <div className={file !== undefined ? 'responseview-unselect' : 'hide'} onClick={() => handleUnselect()}>×</div>
                            </div>
                            <div className={'responseview-filewarning' + (sizeWarning ? ' red' : '')}>
                                Size limit: {sizeLimit} Megabytes
                            </div>
                        </div>
                        <div className='responseview-queries'>
                            {
                                queries.map((query, id) => (
                                    <div key={id} className='responseview-querygroup'>
                                        <div className={'responseview-query closed-query'}>
                                            <span>{(id+1)+'.'}</span>
                                            <span className='responseview-question'>{query.q}</span>
                                        </div>
                                        <div className='responseview-answer'>
                                            <textarea className='responseview-textarea' placeholder='Write answer here' value={values[query.id]} onChange={(event) => (setValues(oldValues => ({...oldValues, [query.id]: event.target.value})))} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResponseView;