import { useLocation, Navigate } from "react-router-dom";
import React, { useContext } from 'react'
import AuthContext from "../context/AuthContext2";

const PrivateRoute = ({children}) => {
    let {user} = useContext(AuthContext)
    let location = useLocation();

    if (user === null) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        // return <Navigate to="/login" state={{ from: location }} />;
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

export default PrivateRoute