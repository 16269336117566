import React, {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import './Table-alt.css';
import AuthContext from '../context/AuthContext2';
import Checkbox from './Checkbox';

const Table = ({rows, selectedItems, onSelect, onPagination, jID, isAdmin = false, refresh, setRefresh}) => {

    const [articleCount, setArticleCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageData, setPageData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate()

    let {communicate} = useContext(AuthContext)

    const handleArticleCount = (result) => {
        if (result?.done) {
            setArticleCount(Number(result['article_count']))
        }
        else if (result?.error) {
            console.log(result.error)
        }
    }

    const handleData = (result) => {
        if (result?.done) {
            setPageData(result['data'])
        }
        else if (result?.error) {
            console.log(result.error)
        }
    }

    useEffect(() => {
        const tableRefresh = async () => {
            if (refresh) {
                await communicate('/api/get-article-count', handleArticleCount, 'POST', {journal_id: jID})
                await communicate('/api/get-page-data', handleData, 'POST', {page: pageIndex + 1, size: rows, journal_id: jID})
                setRefresh(false)
            }
        }
        tableRefresh()
    }, [refresh])
    
    useEffect(() => {
        communicate('/api/get-article-count', handleArticleCount, 'POST', {journal_id: jID})
    },[]);

    useEffect(() => {
        if (articleCount > 0 && totalPages > 0) {
            communicate('/api/get-page-data', handleData, 'POST', {page: pageIndex + 1, size: rows, journal_id: jID})
        }
        else {
            setPageData([])
        }
    },[pageIndex, rows, articleCount, totalPages]);

    useEffect(() => {
        setTotalPages(Math.ceil(articleCount / rows));
        if ((pageIndex >= totalPages) && (totalPages > 0)) {
            setPageIndex(totalPages - 1)
            onPagination();
        }
    }, [articleCount, rows])

    const handlePagination = (index) => {
        setPageIndex(index);
        onPagination();
    }

    return (
        <div className='article-table'>
            <table className='table-container'>
                <thead className='table-thead'>
                    <tr>
                        {isAdmin && <td></td>}
                        <td>Article No.</td>
                        <td className='title-column'>Title</td>
                        <td>Author</td>
                        <td>Status</td>
                        <td>Date</td>
                        {isAdmin && <td>Added By</td>}
                        <td></td>
                    </tr>
                </thead>
                {pageData.length > 0 &&
                    <tbody className='table-tbody'>
                        {pageData.map((item) => (
                            <tr key={item.unique_id}>
                                {isAdmin && <td className='table-centered-checkbox'>
                                    <div className='table-custom-checkbox'>
                                        <Checkbox boxChecked={selectedItems.includes(item.unique_id)} setBoxChecked={() => {onSelect(item.unique_id)}}/>
                                    </div>
                                </td>}
                                <td>{item.number}</td>
                                <td className='title-column'>{item.title}</td>
                                <td>{item.author}</td>
                                <td>{item.status}</td>
                                <td>{item.date}</td>
                                {isAdmin && <td>{item.creator}</td>}
                                <td>
                                    {/* <a href={"item.url"} className={classes.centered_button}> */}
                                        <button className='table-button' onClick={() => navigate(`/${jID}/${item.unique_id}`)}>View</button>
                                        {/* <Link to={`/${jID}/${item.unique_id}`} className={classes.table_link}> <button className={classes.table_button}>View</button></Link> */}
                                    {/* </a> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                }
                <tfoot className='table-tfoot'>
                    <tr>
                        <td colSpan={isAdmin ? 8 : 6}>
                            {Array.from({length: totalPages}).map((_, index) => (
                                <button
                                    key={index}
                                    className={'page-button' + (pageIndex === index ? ' page-button-active' : ' page-button-deactive')}
                                    onClick={() => handlePagination(index)}>{index + 1}</button>
                            ))}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Table;