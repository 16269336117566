import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import moment from 'moment'

const AuthContext = createContext();
export default AuthContext;

function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

export const GetContextData = () => {
    const localTokens = localStorage.getItem('authToken')
    let initialAuthToken = null
    let initialUser = null
    if (localTokens !== null) {
        initialAuthToken = JSON.parse(localTokens)
        initialUser = jwt_decode(initialAuthToken.access)
    }
    let [authToken, setAuthToken] = useState(initialAuthToken);
    let [user, setUser] = useState(initialUser);
    let [loginMessage, setLoginMessage] = useState(null);
    let [loading, setLoading] = useState(false)
    let [loggingIn, setLoggingIn] = useState(false)

    let loginUser = async (e) => {
        e.preventDefault()
        if (e.target.email.value === '') {
            setLoginMessage('Enter email')
        }
        else if (e.target.password.value === '') {
            setLoginMessage('Enter password')
        }
        else {
            setLoggingIn(true)
            try {
                let response = await fetch('https://scienceproof.xyz/api/token/', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({'email': e.target.email.value, 'password': e.target.password.value})
                })
                let data = await response.json()
                if (response.status === 200) {
                    setLoginMessage(null)
                    setAuthToken(data)
                    let jwtData = jwt_decode(data.access)
                    setUser(jwtData)
                    localStorage.setItem('authToken', JSON.stringify(data))
                }
                else if (response.status === 401) {
                    setLoginMessage('Email or password incorrect')
                }
                else {
                    setLoginMessage('Something went wrong')
                }
            } catch (error) {
                console.log(error)
            }
            setLoggingIn(false)
        }
    };

    let logoutUser = () => {
        setAuthToken(null)
        setUser(null)
        localStorage.removeItem('authToken')
    }

    let updateToken = async () => {
        var shouldUpdate = true
        if (user !== null) {
            let now = moment(new Date())
            let then = moment(new Date(Number(user.exp)*1000))
            shouldUpdate = then.diff(now) < 2 * 60 * 1000
        }
        else {
            shouldUpdate = authToken !== undefined && authToken !== null
        }
        if (shouldUpdate) {
            try {
                let response = await fetch('https://scienceproof.xyz/api/token/refresh/', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({'refresh': authToken?.refresh})
                })
                let data = await response.json()
                // console.log(response)
                if (response.status === 200) {
                    setAuthToken(data)
                    let jwtData = jwt_decode(data.access)
                    setUser(jwtData)
                    localStorage.setItem('authToken', JSON.stringify(data))
                }
                else {
                    console.log(1, response)
                    logoutUser()
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (loading) {
            setLoading(false)
        }
    };

    let communicate = async (url, returnData, method='GET', body=null, contentType='application/json', stringify = true) => {
        setLoading(true)
        let request = {method: method,
            headers: {
                'Authorization': 'ProofService ' + String(authToken.access)
            }
        }
        if (contentType !== null && method !== 'GET') {
            request['headers']['Content-Type'] = contentType
        }
        if (body !== null) {
            if (stringify) {
                request['body'] = JSON.stringify(body)
            }
            else {
                request['body'] = body
            }
        }
        while(loading) {
            await timeout(180)
        }
        try {
            let response = await fetch('https://scienceproof.xyz' + url, request)
            let data = await response.json()
            if (response.status === 200) {
                returnData(data)
            }
            else if (response.statusText === 'Unauthorized') {
                console.log(2, response)
                logoutUser()
            }
        } catch (error) {
            returnData({'done': false, 'error': error})
        }
    }

    let contextData = {
        user: user,
        communicate: communicate,
        loginMessage: loginMessage,
        loginUser: loginUser,
        logoutUser: logoutUser,
        loggingIn: loggingIn,
    };

    useEffect(() => {
        if (loading) {
            updateToken()
        }

        let fourMinutes = 4.9 * 60 * 1000
        let interval = setInterval(() => {
            if (authToken) {
                updateToken()
            }
        }, fourMinutes)
        return () => clearInterval(interval)
    }, [loading])

    return (contextData);
}