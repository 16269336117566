import React from "react";
// import React, { Component } from "react";
import ReactDOM from 'react-dom/client'
import HomePage from "./pages/HomePage";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <HomePage />
    // </React.StrictMode>
);