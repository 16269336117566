import React, { useRef } from 'react'
import './Dashboard-alt.css'
import Table from '../components/Table';
import {useState, useEffect, useContext} from 'react';
// import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext2';
import { sizeLimit } from '../context/StaticValues';

const Dashboard = ({isAdmin = false}) => {
    const {jID} = useParams();
    const {communicate} = useContext(AuthContext)
    const [isAddVisible, setIsAddVisible] = useState(false)
    const [selectedItems, setSelectedItems] = useState([]);
    const [inputs, setInputs] = useState({});
    const [file, setFile] = useState(undefined);
    const [sizeWarning, setSizeWarning] = useState(false);
    const [formatWarning, setFormatWarning] = useState(false);
    const [isSendEnabled, setIsSendEnabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    // const navigate = useNavigate()

    const resetAndRefresh = () => {
        setIsAddVisible(false)
        setSelectedItems([])
        setInputs({})
        unselectFile()
        setIsSendEnabled(false)
        setRefresh(true)
    }

    const checkValues = (value) => {
        return inputs.articleNumber !== value && inputs.title !== value && inputs.author !== value && inputs.email !== value && inputs.doi !== value
    }

    const handleSelect = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(p => p.filter(itemId => itemId !== id));
        } else {
            setSelectedItems(p => [...p, id])
        }
    }

    const handlePagination = () => {
        setSelectedItems([])
    }

    const fileInput = useRef()

    if (typeof String.prototype.endsWith !== 'function') {
        // eslint-disable-next-line
        String.prototype.endsWith = function(suffix) {
            return this.toLowerCase().indexOf(suffix, this.length - suffix.length) !== -1;
        };
    }

    const handleFileChange = (event) => {
        const sizeIsOK = event.target.files[0].size < (sizeLimit * 1024 * 1024)
        const formatIsOK = event.target.files[0].name.endsWith('.pdf')
        if (sizeIsOK && formatIsOK) {
            setSizeWarning(false)
            setFormatWarning(false)
            setFile(event.target.files[0])
        }
        else {
            setFile(undefined)
            fileInput.current.value = ''
            setSizeWarning(!sizeIsOK)
            setFormatWarning(!formatIsOK)
        }
    };

    const unselectFile = () => {
        setFile(undefined)
        fileInput.current.value = ''
        setSizeWarning(false)
        setFormatWarning(false)
    }

    const addArticle = () => {
        if (checkValues(undefined) && checkValues('') && file !== undefined) {
            setIsLoading(true)
            const formData = new FormData();
            formData.append('articleNumber', inputs.articleNumber);
            formData.append('title', inputs.title);
            formData.append('author', inputs.author);
            formData.append('email', inputs.email);
            formData.append('doi', inputs.doi);
            formData.append('file', file);
            formData.append('jID', jID);
            communicate('/api/add-article', handleAddArticle, 'POST', formData, null, false)
        }
    }

    const handleAddArticle = (result) => {
        if (result?.done) {
            // navigate(0)
            resetAndRefresh()
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
        setIsLoading(false)
    }

    const closeArticles = () => {
        setIsLoading(true)
        communicate('/api/close-article', handleCloseDelete, 'POST', {'selected_items': selectedItems})
    }

    const deleteArticles = () => {
        setIsLoading(true)
        communicate('/api/delete-article', handleCloseDelete, 'POST', {'selected_items': selectedItems})
    }

    const handleCloseDelete = (result) => {
        if (result?.done) {
            resetAndRefresh()
            // navigate(0)
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
        setSelectedItems([])
        setIsLoading(false)
    }

    useEffect(() => {
        if (isAddVisible && selectedItems.length > 0) {
            setSelectedItems([])
        }
    }, [isAddVisible, selectedItems])

    useEffect(() => {
        setIsSendEnabled(checkValues(undefined) && checkValues('') && file !== undefined)
    }, [inputs, file])

    useEffect(() => {
        document.body.style.cursor= isLoading ? 'wait' : 'default'
    }, [isLoading])

    return (
        <div className='dashboard'>
            <div className='dashboard-buttons'>
                <button className={isLoading ? 'disabled' : undefined} disabled={isLoading} onClick={() => {setIsAddVisible(!isAddVisible); setInputs({}); unselectFile()}}>{isAddVisible ? 'Cancel' : 'Add'}</button>
                <button className={isAdmin ? ((selectedItems.length === 0) || isLoading ? 'disabled' : undefined) : 'hidden'} disabled={(selectedItems.length === 0) || isLoading} onClick={() => closeArticles()}>Close</button>
                <button className={isAdmin ? ((selectedItems.length === 0) || isLoading ? 'disabled' : undefined) : 'hidden'} disabled={(selectedItems.length === 0) || isLoading} onClick={() => deleteArticles()}>Delete</button>
            </div>
            <div className={isAddVisible ? 'dashboard-add' : 'hidden'}>
                <div className='dashboard-addform'>
                    <label><span className='dashboard-input-label'>Article No.:</span><input className='dashboard-input' type='text' name='articleNumber' value={inputs.articleNumber || ''} onChange={(event) => (setInputs(oldValues => ({...oldValues, articleNumber: event.target.value})))} /></label>
                    <label><span className='dashboard-input-label'>Title:</span><input className='dashboard-input' type='text' name='title' value={inputs.title || ''}  onChange={(event) => (setInputs(oldValues => ({...oldValues, title: event.target.value})))} /></label>
                    <label><span className='dashboard-input-label'>Author's Name:</span><input className='dashboard-input' type='text' name='author' value={inputs.author || ''}  onChange={(event) => (setInputs(oldValues => ({...oldValues, author: event.target.value})))} /></label>
                    <label><span className='dashboard-input-label'>DOI:</span><input className='dashboard-input' type='text' name='doi' value={inputs.doi || ''}  onChange={(event) => (setInputs(oldValues => ({...oldValues, doi: event.target.value})))} /></label>
                    <label><span className='dashboard-input-label'>Author's Email:</span><input className='dashboard-input' type='text' name='email' value={inputs.email || ''}  onChange={(event) => (setInputs(oldValues => ({...oldValues, email: event.target.value})))} /></label>
                    <label className='dashboard-file-label'>
                        <span className='dashboard-input-label'>File:</span>
                        <input className='dashboard-file-input' type='file' name='pdf' accept='application/pdf' ref={fileInput} onChange={handleFileChange}></input>
                        <div  className={'dashboard-warnings'}>
                            <span className={'dashboard-file-warning' + (sizeWarning ? ' red' : '')}>Size limit: {sizeLimit} Megabytes</span>
                            <span className={'dashboard-file-warning red' + (formatWarning ? '' : ' hidden')}>File should be PDF</span>
                        </div>
                    </label>
                    <div className='dashboard-add-button'>
                        <button onClick={addArticle} disabled={!isSendEnabled || isLoading} className={isSendEnabled && !isLoading ? undefined : 'disabled'}>Add Article</button>
                    </div>
                </div>
                {/* <button onClick={addArticle} disabled={!isSendEnabled} className={isSendEnabled ? undefined : 'disabled'}>Add Article</button> */}
            </div>
            <div className='dashboard-table'>
                <Table rows={12} selectedItems={selectedItems} onSelect={handleSelect} onPagination={handlePagination} jID={jID} isAdmin={isAdmin} refresh={refresh} setRefresh={setRefresh}/>
            </div>
        </div>
    )
}

export default Dashboard

// import React, {useState, useEffect, useContext} from 'react';
// import classes from "./Dashboard.module.css";
// // import tableData from './page1_data.js'
// import Table from "../components/Table.js";
// import Header from '../components/Header';
// import { useNavigate } from 'react-router-dom'
// import AuthContext from '../context/AuthContext';
// import { useParams } from 'react-router-dom';

// const Dashboard = () => {

//     const {jID} = useParams();

//     let {communicate} = useContext(AuthContext)
//     let navigate = useNavigate()
//     let [title, setTitle] = useState(null)

//     useEffect(() => {
//         document.title = 'Dashboard';
//         communicate('/api/get-journal-title', setJournalTitle, 'POST', {'unique_id':`${jID}`})
//     }, []);

//     const setJournalTitle = (data) => {
//         setTitle(data.title)
//     }

//     const [selectedItems, setSelectedItems] = useState([]);

//     const buttonIsEnabled = selectedItems.length > 0

//     const handleSelect = (id) => {
//         if (selectedItems.includes(id)) {
//             setSelectedItems(p => p.filter(itemId => itemId !== id));
//         } else {
//             setSelectedItems(p => [...p, id])
//         }
//     }

//     const handlePagination = () => {
//         setSelectedItems([])
//     }

//     const handleDelete = (result) => {
//         if (result.done) {
//             navigate(0)
//         }
//     }

//     const deleteArticles = async () => {
//         if (selectedItems.length > 0) {
//             communicate('/api/delete-article', handleDelete, 'POST', selectedItems)
//         }
//     }

//     const goToNew = () => {
//         navigate(`/${jID}/new`)
//     }

//     return (
//         <div className='dashboard'>
//             <Header title={title} />
//             <div className='page-container'>
//                 <main className={classes.main}>
//                     <Table rows={12} selectedItems={selectedItems} onSelect={handleSelect} onPagination={handlePagination} jID={jID} />
//                 </main>
//                 <aside className={classes.sidebar1}>
//                     <button onClick={goToNew} className={classes.button1}>New</button>
//                     <button onClick={deleteArticles} disabled={!buttonIsEnabled} className={`${classes.button2} ${buttonIsEnabled ? classes.button2_active : ''}`}>Delete</button>
//                 </aside>
//             </div>
//         </div>
//     );
// };

// export default Dashboard;