import React, { useContext, useEffect, useState } from 'react'
import './Journals.css'
import AuthContext from '../context/AuthContext2'
import { Link } from 'react-router-dom'

const Journals = () => {

    let {communicate, logoutUser} = useContext(AuthContext)
    // let navigate = useNavigate()
    let [journals, setJournals] = useState([])
    let [isReady, setIsReady] = useState(false)
    let [resultError, setResultError] = useState(false)
    
    const handleJournals = (result) => {
        if (result?.done) {
            setJournals(result.journals)
            setResultError(false)
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
            setResultError(true)
        }
        else {
            setResultError(true)
        }
        setIsReady(true)
    }

    useEffect(() => {
        document.title = 'Journals | Science Proof';
        communicate('/api/get-user-journals', handleJournals)
    }, [])

    return (
        <div className='journals'>
            {((journals.length !== 0) && isReady) && <ul>
                {journals.map((journal, id) => (
                    <li key={id}><img src={journal.logo} alt={journal.unique_id}/><Link to={`/${journal.unique_id}/${'dashboard'}`}>{journal.title}</Link>{(journal.is_admin && journal.counter !== undefined) ? ` (${journal.counter})` : ''}</li>
                ))}
            </ul>}
            {((journals.length === 0) && !resultError && isReady) && 'You are not a member of any journals'}
            {((journals.length === 0) && resultError && isReady) && 'Something went wrong'}
            <div>
                <Link className='journals-logout' onClick={logoutUser}>Logout</Link>
            </div>
        </div>
        // <div className='journals'>
        //     {((journals.length !== 0) && isReady) && <ul>
        //         {journals.map((journal, id) => (
        //             <li key={id}><Link to={`/${journal.unique_id}/${'dashboard'}`}>{journal.title}</Link>{(journal.is_admin && journal.counter !== undefined) ? ` (${journal.counter})` : ''}</li>
        //         ))}
        //     </ul>}
        //     {((journals.length === 0) && !resultError && isReady) && 'You are not a member of any journals'}
        //     {((journals.length === 0) && resultError && isReady) && 'Something went wrong'}
        //     <div>
        //         <Link className='journals-logout' onClick={logoutUser}>Logout</Link>
        //     </div>
        // </div>
    )
}

export default Journals