import React, { useContext, useEffect } from 'react'
import './ManageUsers-alt.css'
import {useState} from 'react';
// import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import UsersTable from './UsersTable';
import AuthContext from '../context/AuthContext2';

const ManageUsers = () => {

    const {jID} = useParams();
    const {communicate} = useContext(AuthContext)
    const [isAddVisible, setIsAddVisible] = useState(false)
    const [inputs, setInputs] = useState({});
    const [isSendAvailable, setIsSendAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    // const navigate = useNavigate()

    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelect = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(p => p.filter(itemId => itemId !== id));
        } else {
            setSelectedItems(p => [...p, id])
        }
    }

    const handlePagination = () => {
        setSelectedItems([])
    }

    const checkValue = (value) => {
        return inputs.fname !== value && inputs.lname !== value && inputs.email !== value
    }

    const addUser = () => {
        if (checkValue(undefined) && checkValue('')) {
        setIsLoading(true)
        communicate('/api/register', handleAdd, 'POST', {jID: jID, first_name: inputs.fname, last_name: inputs.lname, email: inputs.email})
            setInputs({})
        }
    }

    useEffect(() => {
        document.body.style.cursor= isLoading ? 'wait' : 'default'
    }, [isLoading])

    const resetAndRefresh = () => {
        setIsAddVisible(false)
        setSelectedItems([])
        setInputs({})
        setIsSendAvailable(false)
        setRefresh(true)
    }

    const handleAdd = (result) => {
        if (result?.done) {
            resetAndRefresh()
            // navigate(0)
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
        setIsLoading(false)
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const makeAdmin = () => {
        setIsLoading(true)
        communicate('/api/make-admin', handleMakeAdmin, 'POST', {jID: jID, emails: selectedItems})
    }

    const handleMakeAdmin = (result) => {
        if (result?.done) {
            // navigate(0)
            resetAndRefresh()
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
        setSelectedItems([])
        setIsLoading(false)
    }

    const removeUser = () => {
        setIsLoading(true)
        communicate('/api/remove-from-journal', handleRemoveUser, 'POST', {jID: jID, emails: selectedItems})
    }

    const handleRemoveUser = (result) => {
        if (result?.done) {
            resetAndRefresh()
            // navigate(0)
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
        setSelectedItems([])
        setIsLoading(false)
    }

    useEffect(() => {
        if (isAddVisible && selectedItems.length > 0) {
            setSelectedItems([])
        }
    }, [isAddVisible, selectedItems])

    useEffect(() => {
        setIsSendAvailable(checkValue(undefined) && checkValue(''))
    }, [inputs])

    return (
        <div className='musers'>
            <div className='musers-buttons'>
                <button onClick={() => {setIsAddVisible(!isAddVisible); setInputs({})}} className={isLoading ? 'disabled' : undefined} disabled={isLoading}>{isAddVisible ? 'Cancel' : 'Add'}</button>
                <button onClick={() => {selectedItems.length > 0 && makeAdmin()}} className={(selectedItems.length === 0) || isLoading ? 'disabled' : undefined} disabled={(selectedItems.length === 0) || isLoading}>Make Admin</button>
                <button onClick={() => {selectedItems.length > 0 && removeUser()}} className={(selectedItems.length === 0) || isLoading ? 'disabled' : undefined} disabled={(selectedItems.length === 0) || isLoading}>Remove</button>
            </div>
            <div className={isAddVisible ? 'musers-add' : 'hidden'}>
                <div className='musers-addform'>
                    <label><span className='input-label'>First Name:</span><input className='musers-input' type='text' name='fname' value={inputs.fname || ''} onChange={handleChange}></input></label>
                    <label><span className='input-label'>Last Name:</span><input className='musers-input' type='text' name='lname' value={inputs.lname || ''} onChange={handleChange}></input></label>
                    <label><span className='input-label'>Email:</span><input className='musers-input' type='text' name='email' value={inputs.email || ''} onChange={handleChange}></input></label>
                    {/* <label><span className='input-label'>Password:</span><input className='musers-input' type='text' name='password' value={inputs.password || ''} onChange={handleChange}></input></label> */}
                </div>
                <button className={isSendAvailable && !isLoading ? undefined : 'disabled'} onClick={addUser} disabled={!isSendAvailable || isLoading}>Add User</button>
            </div>
            <div className='musers-table'>
                <UsersTable rows={12} selectedItems={selectedItems} onSelect={handleSelect} onPagination={handlePagination} jID={jID} isAdmin={true} refresh={refresh} setRefresh={setRefresh} />
            </div>
        </div>
    )
}

export default ManageUsers