import React from 'react'
import './NotFound.css'
import { Link } from 'react-router-dom'

const NotFound = () => {

    // let navigate = useNavigate()

    return (
        <div className='not-found'>
            <h2>Page not found</h2>
            <Link to='/'>Go to main page</Link>
        </div>
    )
}

export default NotFound