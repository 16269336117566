import { useEffect } from 'react';
import './About.css'

const About = () => {

    useEffect(() => {
        document.title = 'About | Science Proof';
    }, []);

    return (
        <div className='about-page'>
            <img src='/static/media/logo.svg' alt='logo' />
            <div className='about-page-body'>
                <h3>ساینس پروف چیست؟</h3>
                <p>سایت ساینس پروف پلتفرمی برای تسهیل ارتباط نشریات با نویسندگان مقالات علمی است. کلیت عملکرد سایت به این شکل است که نشریه مقاله حروف‌چینی شده را در سایت بارگذاری و پرسش‌هایی که در فرآیند آماده‌سازی جهت انتشار مقاله به وجود آمده را مطرح می‌کند. سایت ساینس پروف با ارسال ایمیل از نویسنده دعوت می‌کند که مقاله حروف‌چینی شده و پرسش‌های مطرح‌شده را بررسی کند و پاسخ موردنیاز را ارائه دهد. سپس بازخورد نویسنده در دسترس ناشر قرار می‌گیرد.</p>
                <p>برای مشاهده نحوه عملکرد سایت می‌توانید این ویدئو را تماشا کنید: <a href='https://scienceproof.xyz/uploads/introduction.mkv'>دانلود</a></p>
                <h3>قیمت‌گذاری</h3>
                <p>قیمت سرویس به صورت سالیانه و به ازای تعداد مجلات محاسبه می‌شود. از آنجایی که بیشتر هزینه‌های نگهداری سایت ارتباط مستقیمی با نرخ ارز دارند، می‌توانید قیمت ریالی را در زمان ثبت سفارش جویا شوید. اما می‌توان گفت که در حال حاضر به طور کلی هزینه سالیانه خدمات به ازای هر مجله معادل ریالی ۵۰۰ دلار آمریکا است. همچنین در صورت سفارش سرویس برای ۱۰ مجله و بیشتر، تخفیف ۲۰ درصدی اعمال خواهد شد.</p>
                <p>برای کسب اطلاعات بیشتر و ثبت سفارش، از طریق <a href='mailto:support@scienceproof.xyz' id='email'>support@scienceproof.xyz</a> با ما در تماس باشید.</p>
            </div>
        </div>
    )
}

export default About