import React, { useContext, useEffect, useState } from 'react'
import './Header-alt.css'
import { Link, useParams } from 'react-router-dom'
import AuthContext from '../context/AuthContext2'

const Header = ({title, logout = true, logo = null}) => {
    const {jID, aID} = useParams();
    const [to, setTo] = useState('/')
    let {logoutUser} = useContext(AuthContext)
    // let navigate = useNavigate()

    useEffect(() => {
        if (jID !== undefined && aID !== undefined) {
            setTo('/'+jID+'/dashboard')
        }
        else {
            setTo('/')
        }
    }, [jID, aID])

    return (
        <div className='header'>
            {((logo !== null) && logout) && <Link className='header-image-link'><img src={logo} alt='Logo' /></Link>}
            {((logo !== null) && !logout) && <img src={logo} alt='Logo' />}
            {!logout && <div className='header-title'>{title}</div>}
            {logout && <Link className='header-title' to={to}>{title}</Link>}
            {logout && <button className='logout' onClick={logoutUser}>Logout</button>}
        </div>
    )
}

export default Header