import React, { useEffect, useState, useContext } from 'react'
import './Manage-alt.css'
import ManageUsers from '../components/ManageUsers'
// import ManageJournal from '../components/ManageJournal'
import Header from '../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext2';
import Dashboard from './Dashboard'

const initialAddress  = (hash) => {
    if (isHashValid(hash)) {
        return hash.slice(1)
    }
    else {
        return 'articles'
    }
}

const isHashValid = (hash) => {
    // return hash === '#articles' || hash === '#users' || hash === '#journal'
    return hash === '#articles' || hash === '#users'
}

function Manage() {

    const {jID} = useParams();
    const {hash} = useLocation()
    const navigate = useNavigate()
    const [selected, setSelected] = useState(initialAddress(hash))
    let [title, setTitle] = useState(null)
    let [logoURL, setLogoURL] = useState(null)
    let [isAdmin, setIsAdmin] = useState(false)
    let {communicate} = useContext(AuthContext)

    useEffect(() => {
        document.title = 'Dashboard | Science Proof'
        communicate('/api/get-journal-title', setJournalTitle, 'POST', {'unique_id':`${jID}`, 'is_admin': true})
    }, [])

    const setJournalTitle = (result) => {
        if (result?.done) {
            setTitle(result.title)
            setIsAdmin(result.is_admin)
            setLogoURL(result.logo)
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
    }

    // useEffect(() => {
    //     navigate(`#${selected}`)
    // }, [selected])

    useEffect(() => {
        if (isHashValid(hash)) {
            setSelected(hash.slice(1))
        }
    }, [hash])

    return (
        <div className='manage'>
            <Header title={title} logo={logoURL} />
            {isAdmin && <div className='manage-side'>
                <div className={'manage-side-item' + (selected === 'articles' ? ' selected' : '')} onClick={() => navigate('#articles')}>Articles</div>
                <div className={'manage-side-item' + (selected === 'users' ? ' selected' : '')} onClick={() => navigate('#users')}>Users</div>
                {/* <div className={'manage-side-item' + (selected === 'journal' ? ' selected' : '')} onClick={() => navigate('#journal')}>Journal</div> */}
            </div>}
            <div className='manage-main'>
                {(isAdmin && selected === 'articles') && <div><Dashboard isAdmin={true} /></div>}
                {!isAdmin && <div><Dashboard /></div>}
                {(isAdmin && selected === 'users') && <div><ManageUsers /></div>}
                {/* {selected === 'journal' && <div><ManageJournal /></div>} */}
            </div>
        </div>
    )
}

export default Manage