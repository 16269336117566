import React, {useState, useEffect, useContext} from 'react';
// import {useNavigate} from 'react-router-dom';
import './UsersTable-alt.css';
import AuthContext from '../context/AuthContext2';
import Checkbox from './Checkbox';

const UsersTable = ({rows, selectedItems, onSelect, onPagination, jID, isAdmin = false, refresh, setRefresh}) => {

    const [usersCount, setUsersCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageData, setPageData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    // const navigate = useNavigate()

    let {communicate} = useContext(AuthContext)

    const handleUsersCount = (result) => {
        if (result?.done) {
            setUsersCount(Number(result['users_count']))
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
    }

    useEffect(() => {
        const tableRefresh = async () => {
            if (refresh) {
                await communicate('/api/get-users-count', handleUsersCount, 'POST', {jID: jID})
                await communicate('/api/get-users', handleData, 'POST', {page: pageIndex + 1, size: rows, jID: jID})
                setRefresh(false)
            }
        }
        tableRefresh()
    }, [refresh])

    const handleData = (result) => {
        if (result?.done) {
            setPageData(result.users)
        }
        else if (result?.error !== undefined) {
            console.log(result.error)
        }
    }
    
    useEffect(() => {
        communicate('/api/get-users-count', handleUsersCount, 'POST', {jID: jID})
    },[]);

    useEffect(() => {
        if (usersCount > 0 && totalPages > 0) {
            communicate('/api/get-users', handleData, 'POST', {page: pageIndex + 1, size: rows, jID: jID})
        }
        else {
            setPageData([])
        }
    },[pageIndex, rows, usersCount, totalPages]);

    useEffect(() => {
        setTotalPages(Math.ceil(usersCount / rows));
        if ((pageIndex >= totalPages) && (totalPages > 0)) {
            setPageIndex(totalPages - 1)
            onPagination();
        }
    }, [usersCount, rows])

    const handlePagination = (index) => {
        setPageIndex(index);
        onPagination();
    }

    return (
        <div className='userstable-container'>
            <table className='userstable'>
                <thead>
                    <tr>
                        <td></td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Status</td>
                    </tr>
                </thead>
                {pageData.length > 0 &&
                    <tbody>
                        {pageData.map((item, id) => (
                            <tr key={id}>
                                <td className='centered_checkbox'>
                                    <div className='custom_checkbox'>
                                        <Checkbox boxChecked={selectedItems.includes(item.email)} setBoxChecked={() => {onSelect(item.email)}}/>
                                    </div>
                                </td>
                                <td>{item.fname + ' ' + item.lname}</td>
                                <td>{item.email}</td>
                                <td>{item.isAdmin ? 'Admin' : 'User'}</td>
                            </tr>
                        ))}
                    </tbody>
                }
                <tfoot className='userstfoot'>
                    <tr>
                        <td colSpan={4}>
                            {Array.from({length: totalPages}).map((_, index) => (
                                <button
                                    key={index}
                                    className={`userspage_button ${pageIndex === index ? 'page_button_active' : 'page_button_deactive'}`}
                                    onClick={() => handlePagination(index)}>{index + 1}</button>
                            ))}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default UsersTable;