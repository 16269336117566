import React, { useEffect, useState } from 'react'
import './NewPassword-alt.css'
import { useNavigate, useParams } from 'react-router-dom'

const NewPassword = () => {

    const {token} = useParams()
    const [inputs, setInputs] = useState({});
    const [canSend, setCanSend] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const navigate = useNavigate()
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const checkValues = (value) => {
        return inputs.a1 !== value && inputs.a2 !== value
    }

    const checkPasswords = () => {
        return checkValues(undefined) && checkValues('') && inputs.a1 === inputs.a2 && inputs.a1.length >= 8 && /[A-Z]+/.test(inputs.a1) && /[a-z]+/.test(inputs.a1) && /[0-9]+/.test(inputs.a1)
    }

    useEffect(() => {
        document.title = 'Reset Password | Science Proof'
        checkToken()
    }, [])

    useEffect(() => {
        setCanSend(checkPasswords())
    }, [inputs])

    const checkToken = async () => {
        try {
            let response = await fetch('https://scienceproof.xyz/api/password-reset-check', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({'token': token})
            })
            let data = await response.json()
            if (response.status === 200) {
                if (data?.done) {
                    setIsValid(true)
                }
                else {
                    setIsValid(false)
                }
            }
            else {
                console.log(response.statusText)
            }
            setIsReady(true)
        } catch (error) {
            console.log(error)
        }
    }

    const resetPassword = async () => {
        if (checkPasswords()) {
            try {
                let response = await fetch('https://scienceproof.xyz/api/password', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({'token': token, 'b': inputs.a1})
                })
                let data = await response.json()
                if (response.status === 200) {
                    if (data?.done) {
                        navigate('/')
                    }
                    else {
                        if (data?.error !== undefined) {
                            console.log(data.error)
                        }
                        setIsValid(false)
                    }
                }
                else {
                    console.log(response.statusText)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    
    return (
        <div className='reset'>
            {(isReady && !isValid) && <div className='not-available'>
                <h2>This page is not available</h2>
                <p>Maybe your link has expired</p>
            </div>}
            {(isReady && isValid) &&<div className='reset-form'>
                <div className='reset-forminputs'>
                    <label><span className='reset-label'>New Password:</span><input className='reset-text' type='password' name='a1' value={inputs.a1 || ''} onChange={handleChange}></input></label>
                    <label><span className='reset-label'>Confirm Password:</span><input className='reset-text' type='password' name='a2' value={inputs.a2 || ''} onChange={handleChange}></input></label>
                </div>
                <button className={'reset-button' + (canSend ? '' : ' disabled')} onClick={resetPassword} disabled={!canSend}>Reset Password</button>
                <div className='reset-message'>Password must be at least 8 characters<br />and contain uppercase letter, lowercase letter, and number.</div>
            </div>}
        </div>
    )
}

export default NewPassword